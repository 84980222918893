import { useMemo } from 'react'
import Head from 'next/head'
import { useStore } from '@bluheadless/ui-logic/src/providers/store'
import { mediaUrl } from '@bluheadless/ui-logic/src/assets'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/system'

const Manifest = () => {
	const store = useStore()
	const theme = useTheme()
	const { locale } = useIntl()
	const { seoMeta, siteName, manifest: manifestConfig } = useConfig()

	const manifest = useMemo(
		() =>
			store.code && {
				name: siteName || seoMeta?.title,
				short_name: siteName || seoMeta?.title,
				description: seoMeta?.description,
				start_url: store.uri,
				scope: store.uri,
				display: 'standalone',
				background_color: theme.palette.background.default,
				theme_color: theme.palette.secondary.main,
				dir: 'ltr',
				lang: locale,
				orientation: 'portrait-primary',
				icons: Object.keys(manifestConfig.icons)
					.filter((size) => !!manifestConfig.icons[size])
					.map((size) => {
						const iconSrc = manifestConfig.icons[size]
						return {
							src: mediaUrl(iconSrc),
							sizes: size,
							type: 'image/png',
						}
					}),
			},
		[
			store.code,
			store.uri,
			theme.palette.background.default,
			theme.palette.secondary.main,
			locale,
			seoMeta,
			siteName,
			manifestConfig,
		]
	)

	return (
		<Head>
			{manifest && (
				<link
					id="manifest"
					rel="manifest"
					href={`data:application/json;charset=utf-8,${encodeURIComponent(JSON.stringify(manifest))}`}
				/>
			)}
		</Head>
	)
}
export default Manifest
