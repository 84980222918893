const Table = {
	MuiTableCell: {
		styleOverrides: {
			body: {
				color: 'var(--color-primary)',
				fontSize: 'var(--font-size-body1)',
				borderRadius: 0,
				'&:first-of-type': {
					borderRadius: '0 !important',
				},
				'&:last-of-type': {
					borderRadius: '0 !important',
				},
			},
			root: {
				fontSize: 'var(--font-size-body1)',
				borderRadius: 0,
				padding: '12px 12px',
				minWidth: 'min-content',
			},
		},
	},
}

export default Table
