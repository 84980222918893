import { Box } from '@mui/material'
import { styled } from '@mui/system'

import { UNPINNED, PINNED, STATIC, NORMAL_TRANSITION } from './constants'

/**
 * To sticky element above the header use
 * {
 *   position: fixed;
 * 	 top: var(--header-sticky-top);
 *   transition: top 0.2s ease-out;
 * }
 */

const shouldForwardProp = (prop) =>
	![
		'disabled',
		'hasScrollHeight',
		'zIndex',
		'translateY',
		'animateUpFrom',
		'mode',
		'sx',
		'overlap',
		'hideOnScrollDown',
	].includes(prop)

export const Root = styled(Box, { shouldForwardProp })`
	position: ${({ disabled, hasScrollHeight, overlap }) =>
		disabled ||
		(!hasScrollHeight &&
			!overlap) /* keep in sync with bluheadless/packages/ui/src/layouts/main-layout/main-layout.js:25 */
			? 'static'
			: 'sticky'};
	--scrollBarWidth: 0;
	width: 100vw;
	top: 0;
	z-index: ${({ zIndex }) => zIndex};
	transform: translateY(${({ translateY }) => translateY}px);
	animation-duration: 0.2s;
	animation-timing-function: ease-out;
	:hover {
		${({
			overlap,
			theme: {
				palette: {
					background: { header },
				},
			},
		}) => (overlap ? `background-color: ${header};` : '')}
	}
	${({
		mode,
		overlap,
		theme: {
			palette: {
				background: { header },
			},
		},
	}) =>
		mode === PINNED || mode === UNPINNED
			? `
				 background-color: ${header};
				 transition: background-color 0.2s ease-out, transform 0.2s ease-out;
		   `
			: overlap
			? `background-color: transparent;`
			: `background-color: ${header};`};
	${({ overlapTop }) => (overlapTop ? `margin-top: ${overlapTop}px;` : '')}
	${({ transition, mode }) =>
		transition === NORMAL_TRANSITION && mode !== STATIC ? 'transition: transform 0.2s ease-out;' : ''}
 ${({ mode }) => (mode === STATIC ? 'transition: background-color 0.2s ease-out, transform 0.2s ease-out;' : '')}
 ${({ theme }) => theme.breakpoints.up('md')} {
		--scrollBarWidth: calc(var(--spacing) * 1.25);
		width: calc(100vw - 7px);
	}
`
