import defaultTheme from '@bluheadless/ui/src/theme/default'

const Dropdown = {
	MuiPopover: {
		styleOverrides: {
			root: {
				borderRadius: 0,
			},
			paper: {
				'&.configurable-options': {
					minWidth: '210px !important',
					maxHeight: '200px !important',
					boxShadow: 'unset',
					width: 'calc(100% - 40px)',
					maxWidth: '100%',
					borderRadius: '0px',
					scrollbarWidth: 'unset',
					scrollbarColor: 'unset',
					[defaultTheme.breakpoints.up('md')]: {
						width: 'auto',
						maxWidth: 'initial',
					},
					[defaultTheme.breakpoints.down('md')]: {
						left: '24px !important',
						width: 'calc(100% - 48px)',
					},
					'&::-webkit-scrollbar': {
						width: '7px',
						height: '5px',
						backgroundColor: 'var(--color-secondary)',
					},

					'&::-webkit-scrollbar-track': {
						borderRadius: '10px',
						backgroundColor: 'var(--color-secondary)',
					},

					'&::-webkit-scrollbar-thumb': {
						background: 'var(--color-grey140)',
						borderRadius: '3.5px',
					},

					'&::-webkit-scrollbar-thumb:hover': {
						background: 'var(--color-grey130)',
					},
					'& .MuiList-root': {
						padding: '0 !important',
						borderColor: 'var(--color-grey100)',
						'.MuiMenuItem-root': {
							borderColor: 'var(--color-grey0)',
							padding: 'var(--spacing-1) 20px',
							'&:hover,&.Mui-selected,&.Mui-focusVisible,&.Mui-focus': {
								background: 'unset',
							},
							'span:first-of-type': {
								fontSize: '18px',
								textTransform: 'uppercase',
								fontFamily: 'var(--font-garamond)',
								fontDisplay: 'auto',
								fontStyle: 'italic',
								fontStretch: 'normal',
								lineHeight: '1.2',
							},
							'span:last-of-type:not(:first-of-type)': {
								fontSize: 'var(--font-size-body1)',
								fontFamily: 'var(--font-garamond)',
								fontDisplay: 'auto',
								fontStyle: 'italic',
								fontStretch: 'normal',
								lineHeight: '1.2',
							},
						},
					},
				},
			},
		},
	},
}
export default Dropdown
