const BHBreadcrumbs = {
	BHBreadcrumbs: {
		defaultProps: {
			separator: '/',
			color: 'primary.main',
			linkProps: {
				component: 'a',
				variant: 'caption',
				underline: 'hover',
				color: 'primary.main',
			},
			activeLinkProps: {
				underline: 'none',
				color: 'primary.main',
				variant: 'body2',
			},
		},
		styleOverrides: {
			ol: {
				justifyContent: 'center',
			},
		},
	},
	MuiBreadcrumbs: {
		styleOverrides: {
			separator: {
				margin: '0px 16px',
			},
			ol: {
				justifyContent: 'center',
			},
		},
	},
}

export default BHBreadcrumbs
