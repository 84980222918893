import { Fade } from '@mui/material'
import { Root, Inner } from './page-loader.styled'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import Head from 'next/head'

const PageLoaderUI = ({ show }) => {
	const { seoMeta } = useConfig()
	return (
		<>
			<Fade id="page-loader" appear={false} in={show}>
				<Root show={show}>
					<Inner>
						{/* eslint-disable-next-line @next/next/no-img-element */}
						<img src="/loader/loader.gif" alt={seoMeta?.title} width="100px" loading="lazy" />
					</Inner>
				</Root>
			</Fade>

			<Head>
				<noscript key="noscript-page-loader">
					<style
						dangerouslySetInnerHTML={{
							__html: `
                                #page-loader {
                                    display: none !important;
                                }
                                `,
						}}
					/>
				</noscript>
			</Head>
		</>
	)
}

export default PageLoaderUI
