import { useRouter } from 'next/router'
import { PAGE_PATH_HOME } from '@bluheadless/ui-logic/src/constants'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { cx } from '@emotion/css'
import { H1, LogoRoot } from './logo.styled'
import Link from '../../molecules/link'
import Image from '../../particles/image'
import { mediaUrl } from '@bluheadless/ui-logic/src/assets'

const Logo = ({ originalWidth, originalHeight, className, ...props }) => {
	const { siteName: siteName } = useConfig()

	const router = useRouter()

	return (
		<LogoRoot className={cx('Logo-root', className)}>
			{router?.asPath.split('?')[0] === PAGE_PATH_HOME ? (
				<H1>
					<Image
						originalHeight={originalHeight}
						originalWidth={originalWidth}
						{...props}
						alt={siteName}
						title={siteName}
						src={mediaUrl(props.src)}
						unoptimized
					/>
				</H1>
			) : (
				<Link href={PAGE_PATH_HOME}>
					<Image
						originalHeight={originalHeight}
						originalWidth={originalWidth}
						{...props}
						alt={siteName}
						title={siteName}
						src={mediaUrl(props.src)}
						unoptimized
					/>
				</Link>
			)}
		</LogoRoot>
	)
}

export default Logo
