import { Collapse as MuiCollapse, Stack as MuiStack } from '@mui/material'
import { styled } from '@mui/system'
import Typography from '../../atoms/typography'
import Button from '../button'

const shouldForwardProp = (prop) => !['additionalContent', 'flipped', 'look'].includes(prop)

const Collapse = styled(MuiCollapse)`
	will-change: auto;
	.MuiCollapse-wrapper {
		position: relative;
	}
`

const Stack = styled(MuiStack)`
	position: absolute;
	right: 13px;
	top: 0;
`

const ButtonStyled = styled(Button, { shouldForwardProp })`
	color: ${({ look, theme }) => (look === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.main)};
	padding: 11px;
	z-index: 1;
	${({ flipped }) =>
		flipped
			? `
			transform: rotate(180deg);
			transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		`
			: ''}
	.MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	}
`

const TypographyStyled = styled(Typography, { shouldForwardProp })`
	background-color: var(--color-secondary);
	color: var(--color-primary);
	font-weight: ${({ theme }) => theme.typography.fontWeightBold};
	padding: ${({ theme }) => theme.spacing(1, 1, 0)};
	${({ additionalContent, theme }) =>
		!additionalContent
			? `
			${theme.breakpoints.up('md')} {
				font-family: ${theme.typography.fontFamily};
				font-weight: ${theme.typography.fontWeightRegular};
			}
		`
			: ''}
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(1, 8, 0)};
	}

	.MuiLink-root {
		color: inherit;
		text-decoration-color: inherit;
	}

	.MuiTypography-root {
		font-family: var(--font-garamond);
		letter-spacing: var(--letter-spacing);
		font-display: auto;
		font-style: italic;
		font-stretch: normal;
	}
`

const CollapseAdditionalContent = styled(Collapse)`
	background-color: ${({ theme }) => theme.palette.primary.contrastText};
	box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 6px 6px -1px rgba(10, 22, 70, 0.1);
	left: 0;
	position: absolute;
	width: 100%;
	z-index: 1;
	.MuiCollapse-wrapper {
		padding: ${({ theme }) => theme.spacing(0, 3.5)};
	}
`

const Container = styled('div')`
	padding: ${({ theme }) => theme.spacing(3.5, 0)};
`

export { ButtonStyled, Collapse, CollapseAdditionalContent, Container, Stack, TypographyStyled }
