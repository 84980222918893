const IconButton = {
	MuiIconButton: {
		defaultProps: {
			size: 'medium',
		},
		styleOverrides: {
			root: {
				'&.iconClose': {
					position: 'absolute',
					padding: 0,
					zIndex: 2,
					top: 22,
					right: 22,
					'& svg': {
						fontSize: 16,
					},
				},
			},
		},
	},
}

export default IconButton
