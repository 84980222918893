import BHChangeCountryLanguageTrigger from '@/theme/components/bh-change-country-language-trigger'
import BHHeader from '@/theme/components/bh-header'
import BHTileBase from '@/theme/components/bh-tile-base'
import BHUpsells from '@/theme/components/bh-upsells'
import Table from '@/theme/components/table'
import defaultTheme from '@bluheadless/ui/src/theme/default'
import { createTheme as createMuiTheme } from '@mui/material/styles' // keep @mui/material/styles to use default theme of @mui/material
import { createTheme } from '@mui/system'
import Accordion from './theme/components/accordion'
import DatePicker from './theme/components/form/date-picker'
import Banners from './theme/components/banners'
import BHBreadcrumbs from './theme/components/bh-breadcrumbs'
import BHNewsletter from './theme/components/bh-newsletter'
import BHQuickBuy from './theme/components/bh-quick-buy'
import Button from './theme/components/button'
import CssBaseline from './theme/components/css-baseline'
import Dialog from './theme/components/dialog'
import BHInput from './theme/components/form/bh-input'
import Input from './theme/components/form/input'
import IconButton from './theme/components/icon-button'
import SvgIcon from './theme/components/svg-icon'
import List from './theme/components/list'
import Dropdown from './theme/components/form/dropdown'
import Form from './theme/components/form/form'
import Checkbox from './theme/components/form/checkbox'
import { fonts } from './theme/fonts'

const htmlFontSize = 14
const fontSize = 14

createMuiTheme({ typography: { htmlFontSize, fontSize } })

const fontFamily = fonts.helveticaNeue.fontFamily
const fontFamilyAlternative = fontFamily

const theme = createTheme(defaultTheme, {
	palette: {
		common: {
			black: '#000000',
			white: '#ffffff',
		},
		black: {
			main: '#000000',
		},
		primary: {
			main: '#000000',
			light: '#333333',
			dark: '#000000',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#ffffff',
			light: '#fcfcfc',
			dark: '#fcfcfc',
			contrastText: '#000000',
		},
		grey: {
			main: '#757575',
			input: '#757575',
			border: '#EAEAEA',
			label: '#AFAFAF',
			light: '#959595',
			filter: '#DEDEDE',
			breadcrumbs: '#9F9F9F',
			vat: '#A0A0A0',
		},
		footer: {
			bgTop: '#FFFFFF',
			bgMiddleTop: '#FFFFFF',
			bgMiddle: '#FFFFFF',
			bgMiddleBottom: '#FFFFFF',
			bgBottom: '#FFFFFF',
			border: '#F2F2F2',
			color: '#000000',
			copyright: '#000000',
			service: '#F2F2F2',
		},
		background: {
			paper: '#FFFFFF',
			default: '#FFFFFF',
			header: '#FFFFFF',
			card: '#F6F6F6',
		},
		tile: {
			background: '#ffffff',
		},
		button: {
			disabled: '#E1E1E1',
		},
	},
	typography: {
		fontFamily,
		fontFamilyAlternative,
		htmlFontSize,
		fontSize,
		fontWeight: 400,
		fontWeightBold: 700,
		fontWeightExtraBold: 900,
		lineHeight: '16px',
		textTransform: 'lowercase',
		headlinebig: {
			...fonts.aGaramondPro,
			fontSize: 'var(--font-size-headlinebig)',
			lineHeight: '28px',
			letterSpacing: 'var(--letter-spacing)',
			fontWeight: 400,
			fontDisplay: 'auto',
			fontStyle: 'italic',
			fontStretch: 'normal',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				letterSpacing: 'var(--letter-spacing-headlinebig)',
				lineHeight: '38px',
			},
		},
		headlinemedium: {
			...fonts.aGaramondPro,
			fontSize: 'var(--font-size-headlinemedium)',
			lineHeight: '40px',
			letterSpacing: 'var(--letter-spacing)',
			fontWeight: 400,
			fontDisplay: 'auto',
			fontStyle: 'italic',
			fontStretch: 'normal',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: 'var(--font-size-headlinemedium)',
				lineHeight: '40px',
				letterSpacing: 'var(--letter-spacing)',
			},
		},
		headlinesmall: {
			...fonts.aGaramondPro,
			fontSize: 'var(--font-size-headlinesmall)',
			lineHeight: '40px',
			letterSpacing: 'var(--letter-spacing)',
			fontWeight: 400,
			fontDisplay: 'auto',
			fontStyle: 'italic',
			fontStretch: 'normal',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: 'var(--font-size-headlinesmall)',
				lineHeight: '40px',
				letterSpacing: 'var(--letter-spacing)',
			},
		},
		subheadline1: {
			...fonts.helveticaNeue,
			fontSize: 'var(--font-size-subheadline1)',
			lineHeight: '22px',
			letterSpacing: 'var(--letter-spacing-body)',
			fontWeight: 400,
		},
		subheadline2: {
			...fonts.helveticaNeue,
			fontSize: 'var(--font-size-subheadline2)',
			lineHeight: '22px',
			letterSpacing: 'var(--letter-spacing)',
			fontWeight: 400,
		},
		headlineDescription: {
			...fonts.helveticaNeue,
			fontSize: 'var(--font-size-headlineDescription)',
			lineHeight: '22px',
			letterSpacing: 'var(--letter-spacing-body)',
			fontWeight: 400,
		},
		h1: {
			...fonts.aGaramondPro,
			fontSize: 'var(--font-size-h1)',
			lineHeight: '32px',
			letterSpacing: 'var(--letter-spacing-h1)',
			fontWeight: 400,
			fontDisplay: 'auto',
			fontStyle: 'italic',
			fontStretch: 'normal',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: 'var(--font-size-h1)',
				letterSpacing: 'var(--letter-spacing-h1)',
				lineHeight: '32px',
			},
		},
		h2: {
			...fonts.aGaramondPro,
			fontSize: 'var(--font-size-h2)',
			lineHeight: '32px',
			letterSpacing: 'var(--letter-spacing-h2)',
			fontWeight: 400,
			fontDisplay: 'auto',
			fontStyle: 'italic',
			fontStretch: 'normal',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: 'var(--font-size-h2)',
				letterSpacing: 'var(--letter-spacing-h2)',
				lineHeight: '32px',
			},
		},
		h3: {
			...fonts.aGaramondPro,
			fontSize: 'var(--font-size-h3)',
			letterSpacing: 'var(--letter-spacing-h3)',
			lineHeight: '32px',
			fontWeight: 400,
			fontDisplay: 'auto',
			fontStyle: 'italic',
			fontStretch: 'normal',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: 'var(--font-size-h3)',
				letterSpacing: 'var(--letter-spacing-h3)',
				lineHeight: '32px',
			},
		},
		h4: {
			...fonts.aGaramondPro,
			fontSize: 'var(--font-size-h4)',
			lineHeight: '32px',
			letterSpacing: 'var(--letter-spacing-h4)',
			fontWeight: 400,
			fontDisplay: 'auto',
			fontStyle: 'italic',
			fontStretch: 'normal',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: 'var(--font-size-h4)',
				letterSpacing: 'var(--letter-spacing-h4)',
				lineHeight: '32px',
			},
		},
		h5: {
			...fonts.aGaramondPro,
			fontSize: 'var(--font-size-h5)',
			lineHeight: '24px',
			letterSpacing: 'var(--letter-spacing-h5)',
			fontWeight: 400,
			fontDisplay: 'auto',
			fontStyle: 'italic',
			fontStretch: 'normal',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: 'var(--font-size-h5)',
				letterSpacing: 'var(--letter-spacing-h5)',
				lineHeight: '24px',
			},
		},
		h6: {
			...fonts.aGaramondPro,
			fontSize: 'var(--font-size-h6)',
			lineHeight: 1,
			letterSpacing: 'var(--letter-spacing-h6)',
			fontWeight: 400,
			fontDisplay: 'auto',
			fontStyle: 'italic',
			fontStretch: 'normal',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: 'var(--font-size-h6)',
				letterSpacing: 'var(--letter-spacing-h6)',
				lineHeight: 1,
			},
		},
		body1: {
			...fonts.helveticaNeue,
			fontSize: 'var(--font-size-body1)',
			lineHeight: '22px',
			fontWeight: 400,
			letterSpacing: 'var(--letter-spacing)',
		},
		body2: {
			...fonts.helveticaNeue,
			fontSize: 'var(--font-size-body2)',
			lineHeight: '20px',
			fontWeight: 400,
			letterSpacing: 'var(--letter-spacing)',
		},
		body3: {
			...fonts.helveticaNeue,
			fontSize: 'var(--font-size-body3)',
			lineHeight: '20px',
			fontWeight: 400,
			letterSpacing: 'var(--letter-spacing)',
		},
		body16: {
			...fonts.helveticaNeue,
			fontSize: 'var(--font-size-body16)',
			lineHeight: 2,
			fontWeight: 400,
			letterSpacing: 'var(--letter-spacing-body)',
		},
		button: {
			...fonts.helveticaNeue,
			fontSize: 'var(--font-size-button)',
			lineHeight: '16px',
			letterSpacing: 'var(--button-letter-spacing)',
			textTransform: 'lowercase',
		},
		caption: {
			...fonts.helveticaNeue,
			fontSize: 'var(--font-size-caption)',
			lineHeight: 1,
			letterSpacing: 'var(--letter-spacing)',
		},
		subtitle1: {
			...fonts.helveticaNeue,
			fontSize: 'var(--font-size-subtitle1)',
			lineHeight: 1,
			letterSpacing: 0,
			fontWeight: 400,
		},
	},

	scrollbar: `
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 7px;
			height: 5px;
			background-color: var(--color-secondary);
		}

		&::-webkit-scrollbar-track {
			border-radius: 10px;
			background-color: var(--color-secondary);
		}

		&::-webkit-scrollbar-thumb {
			background: var(--color-grey140);
			border-radius: 3.5px;
		},

		&::-webkit-scrollbar-thumb:hover {
			background: var(--color-grey130)
		}
	`,

	scrollbarJsx: {
		scrollbarWidth: 'unset',
		scrollbarColor: 'unset',
		'::-webkit-scrollbar': {
			width: '7px',
			height: '5px',
			backgroundColor: 'var(--color-secondary)',
		},
		'::-webkit-scrollbar-track': {
			borderRadius: '10px',
			backgroundColor: 'var(--color-secondary)',
		},
		'::-webkit-scrollbar-thumb': {
			background: 'var(--color-grey140)',
			borderRadius: '3.5px',
		},
		'::-webkit-scrollbar-thumb:hover': {
			background: 'var(--color-grey130)',
		},
	},

	components: {
		...Accordion,
		...Banners,
		...BHBreadcrumbs,
		...BHChangeCountryLanguageTrigger,
		...BHHeader,
		...BHInput,
		...BHNewsletter,
		...BHQuickBuy,
		...BHTileBase,
		...BHUpsells,
		...Button,
		...CssBaseline,
		...DatePicker,
		...Dialog,
		...IconButton,
		...Input,
		...SvgIcon,
		...Table,
		...List,
		...Dropdown,
		...Form,
		...Checkbox,
	},
})

export default theme
