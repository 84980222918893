const List = {
	MuiMenu: {
		styleOverrides: {
			list: {
				borderRadius: 0,
				border: '1px solid var(--color-grey100)',
				marginTop: 0,
				padding: 0,
				'& .MuiMenuItem-root': {
					borderBottom: '1px solid var(--color-grey0)',
					borderRadius: '0',
					padding: 'var(--spacing) calc(var(--spacing) * 2.5)',
					'&:hover': {
						backgroundColor: 'unset',
					},
					'&.Mui-selected': {
						borderRadius: '0',
					},
				},
			},
		},
	},
}

export default List
