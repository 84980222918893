import NextHead from 'next/head'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { mediaUrl } from '@bluheadless/ui-logic/src/assets'
import { isFullURL as testIsFullURL } from '@bluheadless/ui-logic/src/utils/url'
import { parseScript } from '../../utils/parse-script'
import parseHtml from 'html-react-parser'
import { useTheme } from '@mui/system'
import { useMemo } from 'react'

const parseOptionsNoScripts = {
	// eslint-disable-next-line react/display-name
	replace: (node) => {
		switch (node.type) {
			case 'script':
				return <></>
		}
	},
}
const parseOptionsOnlyScripts = {
	// eslint-disable-next-line react/display-name
	replace: (node) => {
		switch (node.type) {
			case 'script':
				return parseScript(node, { idPrefix: 'header_script', strategy: 'beforeInteractive' })
			default:
				return <></>
		}
	},
}

const Head = () => {
	const { headHtml: headHtmlStringified, seoMeta, manifest = {}, favicon } = useConfig()

	const headHtml = useMemo(
		() => (headHtmlStringified ? parseHtml(headHtmlStringified, parseOptionsNoScripts) : null),
		[headHtmlStringified]
	)
	const headScripts = useMemo(
		() => (headHtmlStringified ? parseHtml(headHtmlStringified, parseOptionsOnlyScripts) : null),
		[headHtmlStringified]
	)

	const theme = useTheme()

	return (
		<>
			<NextHead>
				<title>{seoMeta?.title}</title>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no"
				/>
				<meta name="robots" content={seoMeta?.robots} />
				{favicon && <link rel="shortcut icon" href={testIsFullURL(favicon) ? favicon : mediaUrl(favicon)} />}
				{favicon && <link rel="icon" type="image/png" href={testIsFullURL(favicon) ? favicon : mediaUrl(favicon)} />}
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta name="apple-mobile-web-app-status-bar-style" content={theme.palette.primary.main} />
				<meta name="apple-mobile-web-app-title" content={seoMeta?.title} />
				{Object.entries(manifest?.icons || {})
					.filter(([, icon]) => !!icon)
					.map(([size, icon]) => (
						<link key={size} rel="apple-touch-icon" sizes={size} href={mediaUrl(icon)} />
					))}
				{manifest?.icons?.['144x144'] && (
					<meta name="msapplication-TileImage" content={mediaUrl(manifest?.icons?.['144x144'])} />
				)}
				<meta name="msapplication-TileColor" content={theme.palette.primary.main} />
				<meta name="theme-color" content={theme.palette.secondary.main} />
				{seoMeta?.thumbnail && <meta name="thumbnail" content={mediaUrl(seoMeta?.thumbnail)} />}
				{headHtml}
			</NextHead>
			{headScripts}
		</>
	)
}

export default Head
